import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_huhao from '../views/lanhu_huhao/index.vue'
import lanhu_wode from '../views/lanhu_wode/index.vue'
import lanhu_lishidianliang from '../views/lanhu_lishidianliang/index.vue'
import lanhu_bangzhuzhongxin from '../views/lanhu_bangzhuzhongxin/index.vue'
import lanhu_denglu1 from '../views/lanhu_denglu1/index.vue'
import lanhu_denglu2 from '../views/lanhu_denglu2/index.vue'
import lanhu_xiaoxizhongxin from '../views/lanhu_xiaoxizhongxin/index.vue'
import lanhu_shouye from '../views/lanhu_shouye/index.vue'
import lanhu_zhuce from '../views/lanhu_zhuce/index.vue'
import lanhu_denglu3 from '../views/lanhu_denglu3/index.vue'
import lanhu_shishijisuan from '../views/lanhu_shishijisuan/index.vue'
import huhao from '../views/huhao/index.vue'
import userinfo from '../views/userifno/index.vue'
import demo from '../views/demo/index.vue'
import diandu from '../views/diandu/index.vue'
import basefee from '../views/basefee/index.vue'

Vue.use(VueRouter)

//���vue·���ظ���������
//��ȡԭ�Ͷ����ϵ�push����
const originalPush = VueRouter.prototype.push
//�޸�ԭ�Ͷ����е�push����
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: "/lanhu_shouye"
    },
    {
        path: '/demo',
        name: "demo",
        component: demo
    },
    {
        path: '/lanhu_huhao',
        name: 'lanhu_huhao',
        component: lanhu_huhao
    },
    {
        path: '/lanhu_wode',
        name: 'lanhu_wode',
        component: lanhu_wode
    },
    {
        path: '/lanhu_lishidianliang',
        name: 'lanhu_lishidianliang',
        component: lanhu_lishidianliang
    },
    {
        path: '/lanhu_bangzhuzhongxin',
        name: 'lanhu_bangzhuzhongxin',
        component: lanhu_bangzhuzhongxin
    },
    {
        path: '/lanhu_denglu1',
        name: 'lanhu_denglu1',
        component: lanhu_denglu1
    },
    {
        path: '/lanhu_denglu2',
        name: 'lanhu_denglu2',
        component: lanhu_denglu2
    },
    {
        path: '/lanhu_xiaoxizhongxin',
        name: 'lanhu_xiaoxizhongxin',
        component: lanhu_xiaoxizhongxin
    },
    {
        path: '/lanhu_shouye',
        name: 'lanhu_shouye',
        component: lanhu_shouye
    },
    {
        path: '/lanhu_zhuce',
        name: 'lanhu_zhuce',
        component: lanhu_zhuce
    },
    {
        path: '/lanhu_denglu3',
        name: 'lanhu_denglu3',
        component: lanhu_denglu3
    },
    {
        path: '/lanhu_shishijisuan',
        name: 'lanhu_shishijisuan',
        component: lanhu_shishijisuan
    },
    {
        path: '/huhao',
        name: 'huhao',
        component: huhao
    },
    {
        path: '/userinfo',
        name: 'userinfo',
        component: userinfo
    },
    {
        path: '/diandu',
        name: 'diandu',
        component: diandu
    },
    {
        path: '/basefee',
        name: 'basefee',
        component: basefee
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
