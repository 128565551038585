<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="text-wrapper_1 flex-col" @click="back()">
        <span class="text_2">帮助中心</span>
      </div>
    </div>
    <div class="group_2 flex-col">
      <van-collapse accordion :value="activeName" @change="onChange">
        <van-collapse-item :title="item.title" :name="item.title" v-for="item in helpDatas" :key="item.id">
          <div v-html="item.content"></div>
        </van-collapse-item>
      </van-collapse>

      <!--      <div class="group_3 flex-row justify-between">-->
      <!--        <span class="text_3">{{ item.title }}</span>-->
      <!--        <img-->
      <!--            class="label_1"-->
      <!--            referrerpolicy="no-referrer"-->
      <!--            src="./assets/img/SketchPnge9cad70f086bfff7e7bd06bfaa674eb49c1fd4400b331dce67c5c83985dfb6c6.png"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <span class="text_4" v-html="item.content"></span>-->
      <!--      <img-->
      <!--          class="image_4"-->
      <!--          referrerpolicy="no-referrer"-->
      <!--          src="./assets/img/SketchPng315ade5230f8d7572c2c5ebc43d0074587bea2593a152bccd73df31a8fecc7f4.png"-->
      <!--      />-->
      <!--      <div class="group_4 flex-row justify-between">-->
      <!--        <span class="text_5">项目找不到</span>-->
      <!--        <img-->
      <!--            class="label_2"-->
      <!--            referrerpolicy="no-referrer"-->
      <!--            src="./assets/img/SketchPng667bf2ffd2bb6ecc1f5a9269e028603fc33b10819be7654930d552a0290691b9.png"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <img-->
      <!--          class="image_5"-->
      <!--          referrerpolicy="no-referrer"-->
      <!--          src="./assets/img/SketchPng315ade5230f8d7572c2c5ebc43d0074587bea2593a152bccd73df31a8fecc7f4.png"-->
      <!--      />-->
      <!--      <div class="group_5 flex-row justify-between">-->
      <!--        <span class="text_6">如何联系人工客服</span>-->
      <!--        <img-->
      <!--            class="label_3"-->
      <!--            referrerpolicy="no-referrer"-->
      <!--            src="./assets/img/SketchPng667bf2ffd2bb6ecc1f5a9269e028603fc33b10819be7654930d552a0290691b9.png"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <img-->
      <!--          class="image_6"-->
      <!--          referrerpolicy="no-referrer"-->
      <!--          src="./assets/img/SketchPng315ade5230f8d7572c2c5ebc43d0074587bea2593a152bccd73df31a8fecc7f4.png"-->
      <!--      />-->
      <!--      <div class="group_6 flex-row justify-between">-->
      <!--        <span class="text_7">怎么绑定手机号</span>-->
      <!--        <img-->
      <!--            class="label_4"-->
      <!--            referrerpolicy="no-referrer"-->
      <!--            src="./assets/img/SketchPng667bf2ffd2bb6ecc1f5a9269e028603fc33b10819be7654930d552a0290691b9.png"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <img-->
      <!--          class="image_7"-->
      <!--          referrerpolicy="no-referrer"-->
      <!--          src="./assets/img/SketchPng315ade5230f8d7572c2c5ebc43d0074587bea2593a152bccd73df31a8fecc7f4.png"-->
      <!--      />-->
      <!--      <div class="group_7 flex-row justify-between">-->
      <!--        <span class="text_8">第三方登录</span>-->
      <!--        <img-->
      <!--            class="label_5"-->
      <!--            referrerpolicy="no-referrer"-->
      <!--            src="./assets/img/SketchPng667bf2ffd2bb6ecc1f5a9269e028603fc33b10819be7654930d552a0290691b9.png"-->
      <!--        />-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import {getHelps} from "@/api/api";

export default {
  data() {
    return {
      constants: {},
      helpDatas: [],
      activeName: '1',
    };
  },
  mounted() {
    this.getHelps();
  },
  methods: {
    onChange(event) {
      console.log(event)
      this.activeName = event
    },
    back() {
      history.go(-1)
    },
    getHelps() {
      getHelps({}).then(res => {
        if (res && res.code == 20000) {
          this.helpDatas = res.data.records;
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
