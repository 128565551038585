import qs from "qs";

export default {
    data() {
        return {
            exhibition: true,
            appId: 'wxb0fb9e1fee006dd5'
        };
    },
    beforeCreate() {
        this.exhibitionPromise = new Promise((resolve) => {
            this.resolve1 = resolve
        })
    },
    created() {
        this.exhibition = false;
        const {code} = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        if (code) {
            this.getOpenId(code);
        }
        let openId = localStorage.getItem("openId");
        if (openId == 'undefined' || !openId || openId == '') {
            this.goToWeixin();
        } else {
            this.$nextTick(() => {
                this.exhibition = true;
                this.resolve1(this.exhibition)
            });
            return;
        }
    },
    methods: {
        goToWeixin() {
            const currentPage = encodeURIComponent(window.location.href);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${currentPage}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        },
        getOpenId() {

        }
    }
};
