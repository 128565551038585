<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="box_2 flex-row">
        <div class="image-text_1 flex-row justify-between">
          <div class="section_1 flex-col" ></div>
          <div class="section_2 flex-col" v-if="!checkAdmin()" >
            <span class="text-group_1" style="text-overflow: ellipsis;overflow: hidden; max-width: 82vw"
                  :title="accountName">{{ accountName }}</span>
            <div class="text-wrapper_1 flex-col" style="min-width: 40vw"><span class="text_2" style="min-height: 1vh">户号&nbsp;{{ accountNumber }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="group_3 flex-row justify-between">
        <div class="image-text_2 flex-row justify-between">
          <div class="block_1 flex-col"></div>
          <span class="text-group_2">最新资讯</span>
        </div>
        <!--        <div class="image-text_3 flex-row justify-between" @click="moreNews()">-->
        <!--          <span class="text-group_3">更多</span>-->
        <!--          <img-->
        <!--              class="thumbnail_1"-->
        <!--              referrerpolicy="no-referrer"-->
        <!--              src="./assets/img/SketchPng7e3c67523f1ae565583657f15fb067f677b969cb7d444fa535770617ef7b8207.png"-->
        <!--          />-->
        <!--        </div>-->
      </div>
      <!--      <div class="group_4 flex-row justify-between">-->
      <!--        <div class="block_2 flex-col">-->
      <!--          <span class="text_3">北上广最新报告-金融行业非标品成蓝海</span>-->
      <!--          <div class="block_3 flex-row justify-between">-->
      <!--            <div class="text-wrapper_2 flex-col"><span class="text_4">本地资讯</span></div>-->
      <!--            <span class="text_5">07-23</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="block_4 flex-col"></div>-->
      <!--      </div>-->
      <div class="group_5 flex-row justify-between" v-for="item in newsData" :key="item.id"
           @click="gotoNewsDetail(item)">
        <div class="block_5 flex-col">
          <span class="text_6">{{ item.title }}</span>
          <div class="section_3 flex-row justify-between">
            <div class="text-wrapper_3 flex-col"><span class="text_7">{{ getTime19(item) }}</span></div>
            <!--<span class="text_8">07-23</span>-->
          </div>
        </div>
        <!--        <div class="block_6 flex-col"></div>-->
        <img :src="item.img" alt="" class="block_6 flex-col">
      </div>
    </div>
    <div class="group_10 flex-col">
      <div class="group_11 flex-row" @click="gotoWoDe()">
        <div class="text-wrapper_5 flex-col" style="width: 33vw">
          <span class="text_12">上月电量：(度)</span>
          <span class="text_13">{{ this.form.electricQuantity ? this.form.electricQuantity : "--" }}</span>
        </div>
        <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd064733762e62145f9f0c69fc6685d5a71eb54b1919db66ff377c0f21f4b8e3e.png"
        />
        <div class="block_7 flex-col"></div>
        <div class="text-wrapper_6 flex-col" style="width: 33vw">
          <span class="text_14">现状计算方式</span>
          <span class="text_15">{{ getPayWay() }}</span>
        </div>
        <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd064733762e62145f9f0c69fc6685d5a71eb54b1919db66ff377c0f21f4b8e3e.png"
        />
      </div>
      <div class="group_12 flex-col"></div>
      <div class="list_1 flex-row">
        <div class="image-text_6 flex-col" v-for="(item, index) in loopData0" :key="index"
             @click="itemClick(item)">
          <img class="image_5" referrerpolicy="no-referrer" :src="item.image"/>
          <div class="text-group_6 flex-col">
            <span class="text_16" v-html="item.text"></span>
            <span class="text_17" v-html="item.desc"></span>
          </div>
        </div>
      </div>
    </div>
    <foot-menu></foot-menu>
  </div>
</template>
<script>
import FootMenu from "@/components/footer/footMenu";
import {getNews, sjUserInfo} from "../../api/api.js"
import {checkLoging} from "@/utils/common";

export default {
  components: {FootMenu},
  data() {
    return {
      loopData0: [

        {
          image: '/img/real-time-calculate.png',
          text: '电度电费',
          desc: '电度电费计算',
          link: '/diandu'
        },
        {
          image: '/img/history-power.png',
          text: '基本电费',
          desc: '基本电费计算',
          link: '/basefee'
        },
        // {
        //   image: '/img/history-power.png',
        //   text: '历史电量',
        //   desc: '用电记录查询',
        //   link: '/lanhu_lishidianliang'
        // },
        {
          image: '/img/help-me.png',
          text: '帮助中心',
          desc: '常见疑问解答',
          link: '/lanhu_bangzhuzhongxin'
        }
      ],
      constants: {},
      accountName: '',
      accountNumber: '',
      newsData: [],
      form: {
        userId: '',
        upMonthFee: '',
        electricQuantity: '',
        payWay: '',
      }
    };
  },
  created() {
    checkLoging();
    this.form.userId = localStorage.getItem("userId")
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.getNews();
    this.sjUserInfo();
  },
  methods: {
    checkAdmin() {
      return this.accountNumber == '1000000000000'
    },
    gotoWoDe(){
      this.$router.push("/lanhu_wode");
    },
    getPayWay() {
      let val = this.form.payWay ? this.form.payWay : "--";
      if (val.indexOf('：') > 0) {
        let arr = val.split('：');
        return arr[arr.length - 2]
      }
    },
    itemClick(item) {
      this.$router.push(item.link);
    },
    moreNews() {
      this.$router.push("/lanhu_xiaoxizhongxin");
    },
    sjUserInfo() {
      sjUserInfo({"userId": this.form.userId}).then(res => {
        if (res && res.code == 20000) {
          this.form = res.data;
        }
      });
    },
    getNews() {
      getNews().then(res => {
        let baseUrl = process.env.VUE_APP_BASE_API;
        if (res && res.data && res.data.records) {
          for (let i = 0; i < res.data.records.length; i++) {
            res.data.records[i].img = baseUrl + res.data.records[i].img;
          }
          this.newsData = res.data.records;
        }
      })
    },
    getTime19(item) {
      if (item.modifyTime) {
        return item.modifyTime.substr(0, 10);
      }
      return "";
    },
    gotoNewsDetail(item) {
      this.$router.push({
        path: '/lanhu_xiaoxizhongxin',
        query: {
          id: item.id
        }
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
