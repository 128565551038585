<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <div class="block_1 flex-col">
          <div class="box_3 flex-row">
            <span class="text_1">9:41&nbsp;AM</span>
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc7bf5ec534ff0796fcd18c81919a54172d082e448f9b8bfad30b5555bdc28859.png"
            />
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6601bd5a61c2f9d93ba89c098f09c761eea5113bf5f57a0f4f8a84af77b7e916.png"
            />
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc1a791b610deddd0aa621e28e4079646383c06432e746db8d6baca267cdc7e7d.png"
            />
          </div>
          <div class="box_4 flex-row">
            <div class="text-group_1 flex-col">
              <span class="text_2">您好！</span>
              <span class="text_3">欢迎来到政务系统</span>
            </div>
          </div>
        </div>
        <div class="block_2 flex-col">
          <div class="text-wrapper_1 flex-row justify-between">
            <span class="text_4">手机号登录</span>
            <span class="text_5">密码登录</span>
          </div>
          <div class="block_3 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6b231135556d57ef2533c4a8600434f043acc6ed67e71bdcf8e15b678c99c6f4.png"
            />
            <span class="text_6">请输入手机号</span>
          </div>
          <div class="block_4 flex-row">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6bac47e02c6fb937ca8d31582ecb0e798b62d5c962b370bb7ffdd40b5310b869.png"
            />
            <span class="text_7">输入验证码</span>
            <div class="text-wrapper_2">
              <span class="text_8">|</span>
              <span class="text_9">&nbsp;&nbsp;发送验证码</span>
            </div>
          </div>
          <div class="text-wrapper_3 flex-col"><span class="text_10">登录</span></div>
          <div class="text-wrapper_4">
            <span class="text_11">还没有账号？</span>
            <span class="text_12">立即注册</span>
          </div>
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngb3c4bd89ca7c0d25126cb5aebbb247369df3fd3a523721f79ba52c8f436309e7.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
