import axios from 'axios'
import {getToken} from '@/utils/auth'
import router from "@/router";
import store from "@/store/store";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 25000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        store.commit('showLoading')
        // do something before request is sent
        var token = getToken()
        if (token) {
            config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config
    },
    error => {
        store.commit('hideLoading')
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        store.commit('hideLoading')
        const res = response.data
        if (response.headers.token) {
            //如果后台通过header返回token，说明token已经更新，则更新客户端本地token
            console.log(response.headers.token)
        }
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 20000) {

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                //resetToken
            }
            return Promise.reject(res)
        } else {
            return res
        }
    },
    error => {
        store.commit('hideLoading')
        if (error.response.status === 401) {
            console.log("登录过期")
            router.push("/lanhu_denglu2")
            return
        }
        return Promise.reject(error)
    }
)

export default service
