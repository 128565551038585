<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <div class="box_2 flex-row justify-between">
          <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="/img/left-back-white.png"
              @click="back()"
          />
          <span class="text_1">用户信息</span>
        </div>
      </div>
    </div>
    <div class="box_4 flex-col">
      <div class="text-wrapper_2 flex-row justify-between">
        <span class="text_7">手机号</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.contactWay"/>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_11">地址</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.address"/>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_11 flex-col" @click="sjUserInfoUpdate()"><span class="text_26">提交</span></div>
    </div>
  </div>
</template>
<script>

import {Toast} from "vant";
import {checkLoging, checkPhone} from '@/utils/common'
import {sjUserInfo, sjUserInfoUpdate} from "@/api/api";

export default {
  data() {
    return {
      accountName: '',
      accountNumber: '',
      hisSuggest: '',
      constants: {},
      form: {
        userId: '',
        address: '',
        contactWay: '',
        meterMonth: '',
        upMonthFee: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        payWay: '',
        meterTimeUp: '',
        meterTimeThis: '',
        hisSuggest: '',
      }
    };
  },
  mounted() {
    checkLoging();
    this.form.userId = localStorage.getItem("userId")
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.hisSuggest = localStorage.getItem("hisSuggest")
    this.sjUserInfo();
  },
  watch: {},
  computed: {},
  methods: {
    back() {
      history.go(-1)
    },
    sjUserInfo() {
      sjUserInfo({"userId": this.form.userId}).then(res => {
        if (res && res.code == 20000) {
          this.form = res.data;
        }
      });
    },
    sjUserInfoUpdate() {
      if (!this.form.contactWay || this.form.contactWay == '' || !checkPhone(this.form.contactWay)) {
        Toast(`手机号不能为空或格式不正确`);
        return;
      }
      if (!this.form.address || this.form.address == '') {
        Toast(`地址不能为空`);
        return;
      }
      sjUserInfoUpdate(this.form).then(res => {
        if (res.code == 20000 && res.data) {
          this.form = res.data;
          this.back()
        }
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
