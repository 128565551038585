import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import {Button, DropdownMenu, DropdownItem, Cell, Switch, Field, Popup, Picker, Collapse, CollapseItem} from 'vant'
import {RadioGroup, Radio} from 'vant';
import {CheckboxGroup, Checkbox} from 'vant';
import {Tab, Tabs} from 'vant';
import {Tabbar, TabbarItem} from 'vant';
import {NavBar, Icon, Tag} from 'vant';
import {DatetimePicker} from 'vant'
import {Loading} from 'vant'
import {Search} from 'vant'

Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.component('van-datetime-picker', DatetimePicker)
Vue.component('van-popup', Popup)
Vue.component('van-picker', Picker)
Vue.component('van-collapse', Collapse)
Vue.component('van-collapse-item', CollapseItem)
Vue.component('van-loading', Loading)
Vue.component('van-search',Search)

Vue.config.productionTip = false

//全局引入echarts
import * as echarts from 'echarts';
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
