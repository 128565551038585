<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <div class="group_1 flex-row justify-between">
          <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="/img/left-back-white.png"
              @click="back"
          />
          <span class="text_1">历史电量</span>
        </div>
        <div class="group_2 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc00221d528ba7747d1e2f4865dca0747b7f296f4918bd8823f5ec9bc7b28f5a2.png"
            />
            <div class="text-group_1 flex-col">
              <span class="text_2">{{ accountName }}</span>
              <span class="text_3">户号&nbsp;{{ accountNumber }}</span>
            </div>
          </div>
          <div class="text-wrapper_1 flex-col" @click="toSet()"><span class="text_4">设置</span></div>
        </div>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="section_1 flex-row justify-between" style="margin-bottom: 3.5vw" v-if="checkAdmin()">
        <span class="text_23" style="margin-right: auto">户  号</span>
        <div class="flex-row justify-between" @click="showAccountPicker=!showAccountPicker">
          <span class="text_23" style="margin-right: auto">{{ form.accountName }}</span>
          <van-popup v-model="showAccountPicker" position="bottom" style="height: 40%" round>
            <van-picker
                show-toolbar
                :columns="accountDatas"
                title="请选择户号"
                @change="accountChange"
                @confirm="confirmAccount">
              <template #option="option">
                <div style="display: flex; flex-direction: row; align-items: center;">
                  <div>{{ option.accountName }}</div>
                  <div>-{{ option.accountNumber }}</div>
                </div>
              </template>
            </van-picker>
          </van-popup>
          <img
              class="label_21"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
          />
        </div>
      </div>
      <div class="section_1 flex-col">
        <span class="text_23">分时计价(元)</span>
        <div style="width: auto;height: 70vw" id="hisPriceFeeLine"></div>
      </div>
      <div class="section_4 flex-col">
        <span class="text_6">用电量(度)</span>
        <div style="width: auto;height: 70vw" id="hisBar"></div>
      </div>
      <div class="section_7 flex-col">
        <span class="text_23">计价(元)</span>
        <div style="width: auto;height: 70vw" id="hisFeeLine"></div>
      </div>
    </div>
    <div class="box_21 flex-col">
      <div class="text-wrapper_5 flex-row justify-between" v-if="checkSuggest()">
        <span class="text_51">根据您的历史用电量应选：</span>
        <span class="text_52">{{ hisSuggest }}</span>
      </div>
      <span class="paragraph_2">
        *温馨提示：
        <br/>
        1.以上测算结果仅包含工商业输配费用和上网电费，不包含上网环节线损费用、系统运行费用、政府性基金及附加以及居民/农业电费。
        <br/>
        2.测算后，若您发生容量、电价变更等情况，会导致本次测算结果不符合实际情况，届时请重新测算。
        <br/>
        3.计算结果仅供参考。
      </span>
    </div>
  </div>
</template>
<script>
import {checkLoging} from "@/utils/common";
import {getAllUsersByName, getHisAccountQuantity, getHisCalculate} from "@/api/api";


export default {
  data() {
    return {
      form: {
        accountName: '请选择户号',
        accountNumber: '1111',
      },
      showAccountPicker: false,
      accountDatas: [],
      constants: {},
      accountName: '',
      accountNumber: '',
      hisSuggest: '',
      barOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '8%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '用电量',
            type: 'bar',
            barWidth: '60%',
            data: []
          }
        ]
      },
      lineFeeOptions: {
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '单一制电价',
            data: [],
            type: 'line'
          },
          {
            name: '两部制电价(最大需量)',
            data: [],
            type: 'line'
          },
          {
            name: '两部制电价(最大容量)',
            data: [],
            type: 'line'
          }
        ]
      },
      linePriceFeeOptions: {
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '峰电价',
            data: [],
            type: 'line'
          },
          {
            name: '谷电价',
            data: [],
            type: 'line'
          },
          {
            name: '尖峰电价',
            data: [],
            type: 'line'
          }
        ]
      },
    };
  },
  mounted() {
    checkLoging();
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.hisSuggest = localStorage.getItem("hisSuggest")
    console.log(this.hisSuggest)
    if (this.checkAdmin()) {
      this.getALlUsers()
    } else {
      this.initBar();
      this.initFeeLine();
      this.initPriceFeeLine();
    }
  },
  methods: {
    checkSuggest(){
      console.log(this.hisSuggest!=null)
      console.log(this.hisSuggest!='null')
      return this.hisSuggest!=null && this.hisSuggest!='' && this.hisSuggest!='null'
    },
    checkAdmin() {
      return this.accountNumber == '1000000000000'
    },
    getTitle(item) {
      return item.accountName + "-" + item.accountNumber
    },
    initBar(accountNumber) {
      getHisAccountQuantity({"accountNumber": accountNumber}).then(res => {
        if (res && res.code == 20000) {
          this.barOptions.xAxis[0].data = [];
          this.barOptions.series[0].data = [];
          for (let x in res.data) {
            this.barOptions.xAxis[0].data.push(res.data[x].meterMonth);
            this.barOptions.series[0].data.push(res.data[x].electricQuantity);
          }
          let chart = this.$echarts.init(document.getElementById('hisBar'))
          chart.setOption(this.barOptions)
        }
      });

    },
    initFeeLine(accountNumber) {
      getHisCalculate({"accountNumber": accountNumber}).then(res => {
        if (res && res.code == 20000) {
          this.lineFeeOptions.xAxis.data = [];
          this.lineFeeOptions.series[0].data = [];
          this.lineFeeOptions.series[1].data = [];
          this.lineFeeOptions.series[2].data = [];
          for (let x in res.data) {
            this.lineFeeOptions.xAxis.data.push(res.data[x].meterMonth);
          }
          for (let x in res.data) {
            this.lineFeeOptions.series[0].data.push(res.data[x].signalFee);
          }
          for (let x in res.data) {
            this.lineFeeOptions.series[1].data.push(res.data[x].dualFeeNeed);
          }
          for (let x in res.data) {
            this.lineFeeOptions.series[2].data.push(res.data[x].dualFeeCapacity);
          }
          let chart = this.$echarts.init(document.getElementById('hisFeeLine'))
          chart.setOption(this.lineFeeOptions)

          this.linePriceFeeOptions.xAxis.data = [];
          this.linePriceFeeOptions.series[0].data = [];
          this.linePriceFeeOptions.series[1].data = [];
          this.linePriceFeeOptions.series[2].data = [];
          for (let x in res.data) {
            this.linePriceFeeOptions.xAxis.data.push(res.data[x].meterMonth);
          }
          for (let x in res.data) {
            this.linePriceFeeOptions.series[0].data.push(res.data[x].timeFeeUp);
          }
          for (let x in res.data) {
            this.linePriceFeeOptions.series[1].data.push(res.data[x].timeFeeLow);
          }
          for (let x in res.data) {
            this.linePriceFeeOptions.series[2].data.push(res.data[x].timeFeeSharp);
          }
          let chart2 = this.$echarts.init(document.getElementById('hisPriceFeeLine'))
          chart2.setOption(this.linePriceFeeOptions)
        }
      })

    },
    initPriceFeeLine() {

    },
    back() {
      history.go(-1)
    },
    toSet() {
      this.$router.push("/userinfo")
    },
    confirmAccount() {
      this.initBar(this.form.accountNumber);
      this.initFeeLine(this.form.accountNumber);
      this.initPriceFeeLine(this.form.accountNumber);
    },
    accountChange(picker, value, index) {
      console.log(value)
      console.log(index)
      this.form.accountNumber = value.accountNumber
      this.form.accountName = value.accountName
    },
    getALlUsers() {
      getAllUsersByName({}).then(res => {
        if (res && res.code == 20000) {
          this.accountDatas = res.data;
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
