<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngec1f40f9d862ad4b9437eef52626b0657772045cf10457f14a1b5512dbdfbb89.png"
        />
      </div>
    </div>
    <div class="group_2 flex-row">
      <div class="block_1 flex-col">
        <span class="text_1">9:41&nbsp;AM</span>
        <div class="text-group_4 flex-col">
          <span class="text_2">您好！</span>
          <span class="text_3">欢迎来到政务系统</span>
        </div>
        <div class="text-wrapper_5 flex-row justify-between">
          <span class="text_4">注册</span>
          <span class="text_5">登录</span>
        </div>
        <div class="list_3 flex-col">
          <div class="list-items_1 flex-row" v-for="(item, index) in loopData0" :key="index">
            <span class="text_6" v-html="item.lanhutext0"></span>
            <span class="text_7" v-html="item.lanhutext1"></span>
            <span v-if="item.slot2 === 2" class="text_8">获取验证码</span>
            <div v-if="item.slot1 === 1" class="text-wrapper_2">
              <span class="text_9"></span>
              <span class="text_10">5362</span>
            </div>
          </div>
        </div>
        <div class="image-text_3 flex-row justify-between">
          <div class="box_2 flex-col"></div>
          <span class="text-group_2">同意协议并勾选</span>
        </div>
        <div class="text-wrapper_3 flex-col"><span class="text_11">登录</span></div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc1a791b610deddd0aa621e28e4079646383c06432e746db8d6baca267cdc7e7d.png"
        />
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc7bf5ec534ff0796fcd18c81919a54172d082e448f9b8bfad30b5555bdc28859.png"
        />
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6601bd5a61c2f9d93ba89c098f09c761eea5113bf5f57a0f4f8a84af77b7e916.png"
        />
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngb3c4bd89ca7c0d25126cb5aebbb247369df3fd3a523721f79ba52c8f436309e7.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        { lanhutext0: '手机号码', lanhutext1: '请输入手机号码' },
        { lanhutext0: '验证码', lanhutext1: '请输入验证码', slot1: 1 },
        { lanhutext0: '短信验证码', lanhutext1: '请输入短信验证码', slot2: 2 },
        { lanhutext0: '登录密码', lanhutext1: '登录密码' }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
