import router from '@/router'
import {getToken} from "@/utils/auth";

let appId = "";

export function checkOpenId() {
    let openId = localStorage.getItem("openId");
    if (openId == 'undefined' || !openId || openId == '') {
        const url = encodeURIComponent(
            window.location.origin + "/delivery/wxauth.html"
        );
        const currentPage = encodeURIComponent(window.location.href);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=${currentPage}#wechat_redirect`;

    }
}

export function loginOut() {
    localStorage.removeItem("userId")
    localStorage.removeItem("accountName")
    localStorage.removeItem("accountNumber")
    router.push("/lanhu_denglu2")
}

export function checkLoging() {
    let userId = localStorage.getItem("userId");
    let accountName = localStorage.getItem("accountName")
    let accountNumber = localStorage.getItem("accountNumber")
    let token = getToken();
    // let hisSuggest = localStorage.getItem("hisSuggest")
    // console.log(userId)
    if (userId == 'undefined' || !userId || userId == '') {
        router.push("/lanhu_denglu2")
    }
    if (accountName == 'undefined' || !accountName || accountName == '') {
        router.push("/lanhu_denglu2")
    }
    if (accountNumber == 'undefined' || !accountNumber || accountNumber == '') {
        router.push("/lanhu_denglu2")
    }
    if (token == 'undefined' || !token || token == '') {
        router.push("/lanhu_denglu2")
    }
    // if (hisSuggest == 'undefined' || !hisSuggest || hisSuggest == '') {
    //     router.push("/lanhu_denglu2")
    // }
}

export function back() {
    history.go(-1)
}

export function checkNumber(num) {
    return !isNaN(Number(num))
}

export function checkPhone(str) {
    let reg = /^1[3456789]\d{9}$/;
    if (reg.test(str)) {
        return true;
    } else {
        return false;
    }
}
