<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <div class="group_1 flex-col">
          <div class="box_3 flex-col">
            <div class="box_5 flex-row">
              <div class="text-group_1 flex-col">
                <span class="text_2">您好！</span>
                <span class="text_3">欢迎来到电费电价小程序</span>
              </div>
            </div>
          </div>
          <div class="box_6 flex-col">
            <!--            <div class="text-wrapper_1 flex-row justify-between">-->
            <!--              <span class="text_4">手机号登录</span>-->
            <!--              <span class="text_5">密码登录</span>-->
            <!--            </div>-->
            <div class="group_2 flex-row">
              <div class="image-text_1 flex-row justify-between">
                <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngfeb56ac6818bd4f929dba0efdc4a855f0862b562fdbd95ea683b19b1fc6acbc9.png"
                />
                <input type="text" placeholder="请输入用户名或户号" class="input-text" v-model="userName">
              </div>
            </div>
            <div class="group_3 flex-row">
              <div class="image-text_2 flex-row justify-between">
                <img
                    class="label_2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngbe6f463fca5bd1e6daef91aba780fd29d9cf204293806e0133cdd1750a7b5939.png"
                />
                <input type="password" placeholder="请输入密码" class="input-text" v-model="passWord">
              </div>
            </div>
            <div class="text-wrapper_2 flex-col" @click="login()"><span class="text_6">登录</span></div>
            <div class="text-wrapper_3 flex-row justify-between">
<!--              <span class="text_7">遇见问题</span>-->
<!--              <span class="text_8">忘记密码</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {loginUser} from "@/api/api";
import {Toast} from "vant";
import {setToken} from "@/utils/auth";

export default {
  data() {
    return {
      constants: {},
      userName: '',
      passWord: '',
    };
  },
  methods: {
    login() {
      if (this.userName == '') {
        Toast("请输入用户名");
        return;
      }
      if (this.passWord == '') {
        Toast("请输入密码");
        return;
      }
      let param = {
        "userName": this.userName,
        "passWord": this.passWord,
      }
      loginUser(param).then(res => {
        if (res && res.code && res.code == 20000 && res.data) {
          localStorage.setItem("userId", res.data.userId)
          localStorage.setItem("accountName", res.data.accountName)
          localStorage.setItem("accountNumber", res.data.accountNumber)
          localStorage.setItem("hisSuggest", res.data.hisSuggest)
          setToken(res.data.token)
          this.$router.push("/lanhu_shouye")
        }
      }).catch(res => {
        Toast(res.msg)
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
