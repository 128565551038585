<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <div class="box_2 flex-row justify-between">
          <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="/img/left-back-white.png"
              @click="back()"
          />
          <span class="text_1">基本电费</span>
        </div>
        <div class="box_3 flex-row justify-between" v-if="!checkAdmin()">
          <div class="image-text_1 flex-row justify-between">
            <div class="image-wrapper_1 flex-col">
              <img
                  class="image_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc00221d528ba7747d1e2f4865dca0747b7f296f4918bd8823f5ec9bc7b28f5a2.png"
              />
            </div>
            <div class="text-group_1 flex-col">
              <span class="text_2" style="width: 60vw;text-overflow: ellipsis;overflow: hidden;}" :title="accountName">{{
                  accountName
                }}</span>
              <span class="text_3">户号&nbsp;{{ accountNumber }}</span>
            </div>
          </div>
          <div class="text-wrapper_1 flex-col" @click="gotoUserInfo()"><span class="text_4">设置</span></div>
        </div>
      </div>
    </div>
    <div class="box_4 flex-col" v-if="checkAdmin()">
      <div class="section_1 flex-row justify-between">
        <van-popup v-model="showAccountPicker" position="bottom" style="height: 40%"
                   :close-on-click-overlay="true"
                   round>
          <van-search placeholder="请输入户号" v-model="searchAccountName"/>
          <van-picker
              show-toolbar
              :columns="accountDatas"
              title="请选择户号"
              @change="accountChange"
              @confirm="confirmAccount"
              @cancel="showAccountPicker=false">
            <template #option="option">
              <div
                  style="display: flex; flex-direction: row; align-items: center;max-width: 95vw; overflow: hidden;text-overflow: ellipsis;">
                <div>{{ option.accountName }}</div>
                <div>-{{ option.accountNumber }}</div>
              </div>
            </template>
          </van-picker>
        </van-popup>
        <span class="text_23" style="margin-right: auto">户  号</span>
        <div class="flex-row justify-between" @click="showAccountPicker=true">
          <span class="text_23" style="margin-right: auto;max-width: 85vw; text-overflow: ellipsis; overflow: hidden;">{{
              formAdmin.accountName
            }}</span>
          <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
          />
        </div>
      </div>
    </div>
    <van-tabs :active="active" type="card" animated color="rgba(31, 116, 229, 1)" class="box_4 flex-col"
              @change="vanTabsChange">
      <van-tab title="实时计算" name="实时计算">
        <div class="flex-col" style="margin-top: 5.2vw" v-if="active=='实时计算'">
          <div class="block_2 flex-row justify-between">
            <span class="text_5">预估时间</span>
            <div class="image-text_2 flex-row justify-between" @click="showTimePicker=!showTimePicker">
              <span class="text-group_2">{{ form.meterMonth }}</span>
              <van-popup v-model="showTimePicker" position="bottom" style="height: 50%" round get-container="body">
                <van-datetime-picker
                    v-model="currentDate"
                    type="year-month"
                    title="选择预估时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @cancel="onTimeOut"
                    @confirm="onTimeIn"
                    show-toolbar
                />
              </van-popup>
              <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
              />
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="block_4 flex-row justify-between">
            <span class="text_6">电压等级</span>
            <div class="image-text_3 flex-row justify-between" @click="showLevelPicker=!showLevelPicker">
              <span class="text-group_3">{{ form.volLevel }}</span>
              <van-popup v-model="showLevelPicker" position="bottom" style="height: 40%" round get-container="#app">
                <van-picker
                    show-toolbar
                    :columns="levelColumns"
                    title="请选择电压等级"
                    @change="onVolChange"
                    @cancel="cancelVol"
                    @confirm="confirmVol"/>
              </van-popup>
              <img
                  class="label_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
              />
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_2 flex-row justify-between">
            <span class="text_7">总电量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="form.electricQuantity"/>
              <span class="text_8">度</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_10 flex-row justify-between">
            <span class="text_23">运行容量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="form.transformerCapacity"/>
              <span class="text_8">KVA</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_9 flex-row justify-between">
            <span class="text_21">最大需量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="form.maxNeed"/>
              <span class="text_8">KVA</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_4 flex-row justify-between">
            <span class="text_11">容量单价</span>
            <div>
              <span class="text_8">{{ form.volumePrice }}</span>
              <!--              <input type="text" class="input-text" placeholder="请输入" v-model="form.volumePrice"/>-->
              <span class="text_8">元</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_5 flex-row justify-between">
            <span class="text_13">按需量单价</span>
            <div>
              <span class="text_8">{{ form.needPrice }}</span>
              <!--              <input type="text" class="input-text" placeholder="请输入" v-model="form.needPrice"/>-->
              <span class="text_8">元</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_5 flex-row justify-between">
            <span class="text_13">月千伏安超260KVA</span>
            <div>
              <span class="text_8">{{ (form.discount && form.discount!='')?"是":"否" }}</span>
            </div>
          </div>

<!--          <div class="block_line flex-col"></div>-->
<!--          <span class="text_25" v-if="form.discount && form.discount!=''">*&nbsp;月千伏安超260KVA</span>-->

          <div class="text-wrapper_11 flex-col" @click="calculateAgain()"><span class="text_26">计  算</span></div>
          <div class="box_5 flex-col" style="margin-top: 0.54vw">
            <div class="text-wrapper_15 flex-row justify-between" v-if="checkData(form.signalFee)">
              <span class="text_27">单一制电价：</span>
              <span class="text_28">{{ form.signalFee }}&nbsp;元</span>
            </div>
            <div class="group_3 flex-col" v-if="checkData(form.dualFeeCapacity)"></div>
            <div class="text-wrapper_14 flex-row justify-between" v-if="checkData(form.dualFeeCapacity)">
              <span class="text_31">按容量基本电费：</span>
              <span class="text_32">{{ form.dualFeeCapacity }}&nbsp;元</span>
            </div>
            <div class="group_1 flex-col" v-if="checkData(form.dualFeeNeed)"></div>
            <div class="group_2 flex-row justify-between" v-if="checkData(form.dualFeeNeed)">
              <div class="image-text_4 flex-row justify-between">
                <span class="text-group_4">按需量基本电费：</span>
                <div class="text-wrapper_13 flex-col" v-if="form.discount && form.discount!=''">
                  <span class="text_29">9折</span>
                </div>
              </div>
              <span class="text_30">{{ form.dualFeeNeed }}&nbsp;元</span>
            </div>
            <div class="group_5 flex-col"
                 v-if="checkData(form.criticalMaxNeed) && checkData(form.cirticalMaxCapactity)"></div>
            <div class="text-group_5 flex-col" v-if="checkData(form.criticalMaxNeed) && checkData(form.cirticalMaxCapactity)">
              <span class="text_35">临界值：</span>
              <span class="text_36">          两部制的最大需量:&nbsp;{{ form.criticalMaxNeed }}KVA        </span>
              <span class="text_36">          两部制的最大容量:&nbsp;{{ form.cirticalMaxCapactity }}KVA        </span>
            </div>
            <div class="group_6 flex-col" v-if="checkData(form.suggest) && checkData(form.economy)"></div>
            <div class="group_7 flex-row justify-between" v-if="checkData(form.suggest) && checkData(form.economy)">
              <span class="text_37">建议计费方式:</span>
              <div class="text-group_6 flex-col">
                <span class="text_38">{{ form.suggest }}</span>
                <span class="text_39">预计节约{{ form.economy }}元</span>
              </div>
            </div>
            <div class="group_4 flex-col" v-if="checkData(form.suggest)"></div>
            <div class="text-wrapper_15 flex-row justify-between" v-if="checkData(form.suggest)">
              <span class="text_40">根据您用电量建议：</span>
              <span class="text_41">{{ form.suggest }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="历史计算" name="历史计算">
        <div class="flex-col" style="margin-top: 5.2vw" v-if="active=='历史计算'">
          <div class="block_2 flex-row justify-between">
            <span class="text_5">预估时间</span>
            <div class="image-text_2 flex-row justify-between" @click="showTimePickerHis=!showTimePickerHis">
              <span class="text-group_2">{{ formHis.meterMonth }}</span>
              <van-popup v-model="showTimePickerHis" position="bottom" style="height: 50%" round get-container="#app">
                <van-datetime-picker
                    v-model="currentDateHis"
                    type="year-month"
                    title="选择预估时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @cancel="onTimeOutHis"
                    @confirm="onTimeInHis"
                    show-toolbar
                />
              </van-popup>
              <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
              />
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="block_4 flex-row justify-between">
            <span class="text_6">电压等级</span>
            <div class="image-text_3 flex-row justify-between" @click="showLevelPickerHis=!showLevelPickerHis">
              <span class="text-group_3">{{ formHis.volLevel }}</span>
              <van-popup v-model="showLevelPickerHis" position="bottom" style="height: 40%" round get-container="#app">
                <van-picker
                    show-toolbar
                    :columns="levelColumns"
                    title="请选择电压等级"
                    @change="onVolChangeHis"
                    @cancel="cancelVolHis"
                    @confirm="confirmVolHis"/>
              </van-popup>
              <img
                  class="label_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
              />
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_2 flex-row justify-between">
            <span class="text_7">总电量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="formHis.electricQuantity"/>
              <span class="text_8">度</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_10 flex-row justify-between">
            <span class="text_23">运行容量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="formHis.transformerCapacity"/>
              <span class="text_8">KVA</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_9 flex-row justify-between">
            <span class="text_21">最大需量</span>
            <div>
              <input type="text" class="input-text" placeholder="请输入" v-model="formHis.maxNeed"/>
              <span class="text_8">KVA</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_4 flex-row justify-between">
            <span class="text_11">容量单价</span>
            <div>
              <span class="text_8">{{ formHis.volumePrice }}</span>
              <!--              <input type="text" class="input-text" placeholder="请输入" v-model="formHis.volumePrice"/>-->
              <span class="text_8">元</span>
            </div>
          </div>
          <div class="block_line flex-col"></div>
          <div class="text-wrapper_5 flex-row justify-between">
            <span class="text_13">按需量单价</span>
            <div>
              <span class="text_8">{{ formHis.needPrice }}</span>
              <!--              <input type="text" class="input-text" placeholder="请输入" v-model="formHis.needPrice"/>-->
              <span class="text_8">元</span>
            </div>
          </div>

          <div class="block_line flex-col"></div>
          <div class="text-wrapper_5 flex-row justify-between">
            <span class="text_13">月千伏安超260KVA</span>
            <div>
              <span class="text_8">{{ (formHis.discount && formHis.discount!='')?"是":"否" }}</span>
            </div>
          </div>

<!--          <div class="block_line flex-col"></div>-->
<!--          <span class="text_25" v-if="formHis.discount && formHis.discount!=''">*&nbsp;月千伏安超260KVA</span>-->
          <div class="text-wrapper_11 flex-col" @click="calculateAgainHis()"><span class="text_26">重新计算</span></div>
          <div class="box_5 flex-col" style="margin-top: 0.54vw">
            <div class="text-wrapper_15 flex-row justify-between" v-if="checkData(formHis.signalFee)">
              <span class="text_27">单一制电价：</span>
              <span class="text_28">{{ formHis.signalFee }}&nbsp;元</span>
            </div>
            <div class="group_3 flex-col" v-if="checkData(formHis.dualFeeCapacity)"></div>
            <div class="text-wrapper_14 flex-row justify-between" v-if="checkData(formHis.dualFeeCapacity)">
              <span class="text_31">按容量基本电费：</span>
              <span class="text_32">{{ formHis.dualFeeCapacity }}&nbsp;元</span>
            </div>
            <div class="group_1 flex-col" v-if="checkData(formHis.dualFeeNeed)"></div>
            <div class="group_2 flex-row justify-between" v-if="checkData(formHis.dualFeeNeed)">
              <div class="image-text_4 flex-row justify-between">
                <span class="text-group_4">按需量基本电费：</span>
                <div class="text-wrapper_13 flex-col" v-if="formHis.discount && formHis.discount!=''">
                  <span class="text_29">9折</span>
                </div>
              </div>
              <span class="text_30">{{ formHis.dualFeeNeed }}&nbsp;元</span>
            </div>
            <div class="group_5 flex-col"
                 v-if="checkData(formHis.criticalMaxNeed) && checkData(formHis.cirticalMaxCapactity)"></div>
            <div class="text-group_5 flex-col" v-if="checkData(formHis.criticalMaxNeed) && checkData(formHis.cirticalMaxCapactity)">
              <span class="text_35">临界值：</span>
              <span class="text_36">          两部制的最大需量:&nbsp;{{ formHis.criticalMaxNeed }}KVA        </span>
              <span class="text_36">          两部制的最大容量:&nbsp;{{ formHis.cirticalMaxCapactity }}KVA        </span>
            </div>
            <div class="group_6 flex-col" v-if="checkData(formHis.suggest) && checkData(formHis.economy)"></div>
            <div class="group_7 flex-row justify-between" v-if="checkData(formHis.suggest) && checkData(formHis.economy)">
              <span class="text_37">建议计费方式:</span>
              <div class="text-group_6 flex-col">
                <span class="text_38">{{ formHis.suggest }}</span>
                <span class="text_39">预计节约{{ formHis.economy }}元</span>
              </div>
            </div>
            <div class="group_4 flex-col" v-if="checkData(formHis.suggest)"></div>
            <div class="text-wrapper_15 flex-row justify-between" v-if="checkData(formHis.suggest)">
              <span class="text_40">根据您用电量建议：</span>
              <span class="text_41">{{ formHis.suggest }}</span>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="box_6 flex-col">
      <span class="paragraph_1">
        *温馨提示：
        <br/>
        1.以上测算结果仅包含工商业输配费用和上网电费，不包含上网环节线损费用、系统运行费用、政府性基金及附加以及居民/农业电费。
        <br/>
        2.测算后，若您发生容量、电价变更等情况，会导致本次测算结果不符合实际情况，届时请重新测算。
        <br/>
        3.计算结果仅供参考。
      </span>
    </div>
  </div>
</template>
<script>

import {Toast} from "vant";
import {checkLoging, checkNumber} from '@/utils/common'
import {calculateQuantity, getAllUsersByName, getMaxNeedVolumePrice, getQuantity, getUserVolLevel} from "@/api/api";

export default {
  data() {
    return {
      searchAccountName: '',
      showAccountPicker: false,
      accountDatas: [],
      active: '实时计算',
      accountName: '',
      accountNumber: '',
      hisSuggest: '',
      constants: {},
      minDate: new Date(2000, 1, 1),
      maxDate: new Date(2060, 12, 31),
      currentDate: new Date(),
      currentDateHis: new Date(),
      showTimePicker: false,
      showLevelPicker: false,
      showTimePickerHis: false,
      showLevelPickerHis: false,
      levelColumns: ['交流10kV', '交流35kV', '交流110kV', '交流220kV'],
      formAdmin: {
        accountName: '请选择户号',
        accountNumber: '',
      },
      form: {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: '',
        volLevel: '交流10kV',
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        flatPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
        timeFeeFlat: '',
      },
      formHis: {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: '',
        volLevel: '交流10kV',
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        flatPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
        timeFeeFlat: '',
      }
    };
  },
  mounted() {
    checkLoging();
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.hisSuggest = localStorage.getItem("hisSuggest")
    this.form.meterMonth = this.getTime()
    this.formHis.meterMonth = this.getTimeHis()
    if (this.checkAdmin()) {
      this.getMaxNeedVolumePrice(this.form.volLevel, 1)
      this.getALlUsers()
    } else {
      this.getUserVolLevel(this.accountNumber)
    }
    this.setAccount()
  },
  watch: {
    searchAccountName: {
      handler() {
        this.getALlUsers(this.searchAccountName)
      }
    }
  },
  computed: {},
  methods: {
    setAccount() {
      if (this.checkAdmin()) {
        this.form.accountNumber = this.formAdmin.accountNumber;
        this.form.accountName = this.formAdmin.accountName;
        this.formHis.accountNumber = this.formAdmin.accountNumber;
        this.formHis.accountName = this.formAdmin.accountName;
      } else {
        this.form.accountNumber = this.accountNumber;
        this.form.accountName = this.accountName;
        this.formHis.accountNumber = this.accountNumber;
        this.formHis.accountName = this.accountName;
      }
    },
    checkAdmin() {
      return this.accountNumber == '1000000000000'
    },
    checkData(data) {
      return data && data != 0 && data != "0" && data != ""
    },
    getDianDu(data) {
      if (data.timeFeeUp && checkNumber(data.timeFeeUp) &&
          data.timeFeeLow && checkNumber(data.timeFeeLow) &&
          data.timeFeeSharp && checkNumber(data.timeFeeSharp)) {
        let diandu = Number.parseFloat(data.timeFeeUp) +
            Number.parseFloat(data.timeFeeLow) +
            Number.parseFloat(data.timeFeeSharp)
        return diandu.toFixed(2)*100/100;
      }
      return '';
    },
    back() {
      history.go(-1)
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    // 取消时间弹出框按钮
    onTimeOut() {
      this.showTimePicker = false
    },
    onTimeOutHis() {
      this.showTimePickerHis = false
    },
    // 时间弹出框  确认按钮
    onTimeIn() {
      this.form.meterMonth = this.getTime()
      this.showTimePicker = false;
      // this.getQuantity();
    },
    onTimeInHis() {
      this.formHis.meterMonth = this.getTimeHis()
      this.showTimePickerHis = false;
      this.getQuantityHis();
    },
    getTime() {
      return this.timeFormat(this.currentDate)
    },
    getTimeHis() {
      return this.timeFormat(this.currentDateHis)
    },
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      // let day = time.getDate();
      return year + '年' + month + '月'
    },
    confirmVol() {
      this.showLevelPicker = false
      // this.getQuantity();
      this.getMaxNeedVolumePrice(this.form.volLevel, 1)
    },
    confirmVolHis() {
      this.showLevelPickerHis = false
      this.getQuantityHis();

    },
    cancelVol() {
      this.showLevelPicker = false
    },
    cancelVolHis() {
      this.showLevelPickerHis = false
    },
    onVolChange(picker, value, index) {
      console.log(value)
      console.log(index)
      this.form.volLevel = value
    },
    onVolChangeHis(picker, value, index) {
      console.log(index)
      this.formHis.volLevel = value
    },
    getQuantity() {
      this.resetForm()
      let request = {
        "accountNumber": this.accountNumber,
        "volLevel": this.form.volLevel,
        "meterMonth": this.form.meterMonth,
      }
      getQuantity(request).then(res => {
        if (res.code == 20000 && res.data) {
          this.form = res.data;
        }
      });
    },
    getQuantityHis() {
      this.resetFormHis()
      this.setAccount()
      let request = {
        "accountNumber": this.formHis.accountNumber,
        "volLevel": this.formHis.volLevel,
        "meterMonth": this.formHis.meterMonth,
        // "volumePrice": this.form.volumePrice,
        // "needPrice": this.form.needPrice,
      }
      getQuantity(request).then(res => {
        if (res.code == 20000) {
          if (res.data) {
            this.formHis = res.data;
          }
          this.getMaxNeedVolumePrice(this.formHis.volLevel, 2)
        }
      }).catch(res => {
        if (res.msg) {
          // Toast(res.msg)
        }
      });
    },
    calculateAgain() {
      if (!this.form.electricQuantity || this.form.electricQuantity == '' || !checkNumber(this.form.electricQuantity)) {
        Toast(`总电量不能为空且为数字.`);
        return;
      }
      if (!this.form.maxNeed || this.form.maxNeed == '' || !checkNumber(this.form.maxNeed)) {
        Toast(`最大需量不能为空且为数字.`);
        return;
      }
      if (!this.form.transformerCapacity || this.form.transformerCapacity == '' || !checkNumber(this.form.transformerCapacity)) {
        Toast(`运行容量不能为空且为数字.`);
        return;
      }
      // if (!this.form.needPrice || this.form.needPrice == '' || !checkNumber(this.form.needPrice)) {
      //   Toast(`按需量单价不能为空且为数字.`);
      //   return;
      // }
      // if (!this.form.volumePrice || this.form.volumePrice == '' || !checkNumber(this.form.volumePrice)) {
      //   Toast(`容量单价不能为空且为数字.`);
      //   return;
      // }
      this.setAccount()
      // if (!this.formHis.accountNumber || this.formHis.accountNumber == '') {
      //   Toast(`请选择户号.`);
      //   return;
      // }
      calculateQuantity(this.form).then(res => {
        console.log(res)
        if (res.code == 20000 && res.data) {
          this.form = res.data;
        }
      })
    },
    calculateAgainHis() {
      if (!this.formHis.electricQuantity || this.formHis.electricQuantity == '' || !checkNumber(this.formHis.electricQuantity)) {
        Toast(`总电量不能为空且为数字.`);
        return;
      }
      if (!this.formHis.maxNeed || this.formHis.maxNeed == '' || !checkNumber(this.formHis.maxNeed)) {
        Toast(`最大需量不能为空且为数字.`);
        return;
      }
      if (!this.formHis.transformerCapacity || this.formHis.transformerCapacity == '' || !checkNumber(this.formHis.transformerCapacity)) {
        Toast(`运行容量不能为空且为数字.`);
        return;
      }
      // if (!this.formHis.needPrice || this.formHis.needPrice == '' || !checkNumber(this.formHis.needPrice)) {
      //   Toast(`按需量单价不能为空且为数字.`);
      //   return;
      // }
      // if (!this.formHis.volumePrice || this.formHis.volumePrice == '' || !checkNumber(this.formHis.volumePrice)) {
      //   Toast(`容量单价不能为空且为数字.`);
      //   return;
      // }
      this.setAccount()
      // if (!this.formHis.accountNumber || this.formHis.accountNumber == '') {
      //   Toast(`请选择户号.`);
      //   return;
      // }
      calculateQuantity(this.formHis).then(res => {
        console.log(res)
        if (res.code == 20000 && res.data) {
          this.formHis = res.data;
        }
      })
    },
    gotoUserInfo() {
      this.$router.push("/userinfo")
    },
    resetForm() {
      let data = {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: this.form.meterMonth,
        volLevel: this.form.volLevel,
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
      }
      this.form = data;
    },
    resetFormHis() {
      let data = {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: this.formHis.meterMonth,
        volLevel: this.formHis.volLevel,
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
      }
      this.formHis = data;
    },
    vanTabsChange(name, title) {
      this.active = title
      if (title == "历史计算") {
        this.getMaxNeedVolumePrice(this.formHis.volLevel, 2)
      }
    },
    getMaxNeedVolumePrice(volLevel, type) {
      getMaxNeedVolumePrice({"volLevel": volLevel}).then(res => {
        if (type == 1) {
          this.form.needPrice = "";
          this.form.volumePrice = "";
          if (res && res.code == 20000 && res.data) {
            for (let x in res.data) {
              if (res.data[x].需量单价) {
                this.form.needPrice = res.data[x].需量单价;
              }
              if (res.data[x].容量单价) {
                this.form.volumePrice = res.data[x].容量单价;
              }
            }
          }
        }
        if (type == 2) {
          this.formHis.needPrice = "";
          this.formHis.volumePrice = "";
          if (res && res.code == 20000 && res.data) {
            for (let x in res.data) {
              if (res.data[x].需量单价) {
                this.formHis.needPrice = res.data[x].需量单价;
              }
              if (res.data[x].容量单价) {
                this.formHis.volumePrice = res.data[x].容量单价;
              }
            }
          }
        }
      }).catch(res => {
        if (res.msg) {
          // Toast(res.msg)
        }
      })
    },
    getALlUsers(accountName) {
      if (!accountName) {
        accountName = "";
      }
      getAllUsersByName({"accountName": accountName}).then(res => {
        if (res && res.code == 20000) {
          this.accountDatas = res.data;
        }
      });
    },
    confirmAccount(value) {
      this.showAccountPicker = false
      this.formAdmin.accountNumber = value.accountNumber
      this.formAdmin.accountName = value.accountName
      this.setAccount()
      this.getUserVolLevel(this.formAdmin.accountNumber)
    },
    accountChange(picker, value, index) {
      console.log(value)
      console.log(index)
      // this.formAdmin.accountNumber = value.accountNumber
      // this.formAdmin.accountName = value.accountName
      // this.setAccount()
    },
    getUserVolLevel(accountNumber) {
      console.log(this.levelColumns)
      getUserVolLevel({"accountNumber": accountNumber}).then(res => {
        if (res && res.code == 20000 && res.data)
          this.levelColumns = []
        for (let x in res.data) {
          this.levelColumns.push(res.data[x].vol_level)
        }
        console.log(this.levelColumns)
        if (this.levelColumns.length > 0) {
          this.form.volLevel = this.levelColumns[0]
          this.formHis.volLevel = this.levelColumns[0]
          this.getMaxNeedVolumePrice(this.form.volLevel, 1)
          this.getQuantityHis();
        }
      });
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>

<style lang="scss" scoped>
::v-deep .van-tabs__nav--card {
  margin: 0 !important
}
</style>
