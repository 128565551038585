<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <router-view/>
  </div>
</template>

<style lang="css" src="./assets/common.css">
</style>
<style type="text/css">
</style>

<script>
import {mapState} from 'vuex'
import Loading from '@/components/loading.vue'
export default {
  computed: {
    ...mapState(['LOADING'])
  },
  components: {Loading}
}
</script>
