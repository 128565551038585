<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="section_1 flex-col">
        <div class="block_1 flex-col">
          <div class="box_2 flex-row">
            <div class="text-group_1 flex-col">
              <span class="text_2">您好！</span>
              <span class="text_3">请您绑定户号</span>
            </div>
          </div>
        </div>
        <div class="block_2 flex-col">
          <div class="text-wrapper_1 flex-col"><span class="text_4">请输入户号</span></div>
          <span class="text_5">未注册的手机号验证后自动创建账户</span>
          <div class="text-wrapper_2 flex-col"><span class="text_6" @click="bindHuHao()">绑定</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    bindHuHao() {
      this.$router.push("/lanhu_shouye")
    }
  }

};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
