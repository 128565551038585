<template>
  <div>1111111111111111</div>
</template>

<script>
import weixin from "@/views/mixins/weixin";

export default {
  mixins: [weixin],
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    login() {
      this.$router.push("/lanhu_shouye")
    }
  }
};
</script>
