import request from '@/utils/request'
import qs from 'qs'

export function login(data) {
    return request({
        url: '/account/login',
        method: 'post',
        data: qs.stringify({
            'username': data.username,
            'password': data.password
        })
    })
}

export function getInfo() {
    return request({
        url: '/account/info',
        method: 'get'
    })
}

export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    })
}

export function updatePwd(params) {
    return request({
        url: '/account/updatePwd',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function news(params) {
    return request({
        url: '/offcialsite/news',
        method: 'get',
        data: qs.stringify(params)
    })
}


export function getQuantity(params) {
    return request({
        url: '/powerCalculate/getQuantity',
        method: 'post',
        // data: qs.stringify({
        //     "accountNumber": params.accountNumber,
        //     "volLevel": params.volLevel,
        //     "meterMonth": params.meterMonth,
        // })
        // data: qs.stringify(params)
        data: params
    })
}

export function getQuantityAddCalculate(params) {
    return request({
        url: '/powerCalculate/getQuantityAddCalculate',
        method: 'post',
        data: params
    })
}

export function calculateQuantity(params) {
    return request({
        url: '/powerCalculate/calculateQuantity',
        method: 'post',
        data: params
    })
}

export function loginUser(params) {
    return request({
        url: '/powerSystem/loginUser',
        method: 'post',
        data: qs.stringify(params)
    })
}

export function getNews() {
    return request({
        url: '/powerSystem/getNews',
        method: 'get'
    })
}

export function getNewsById(params) {
    return request({
        url: '/powerSystem/getNewsById',
        method: 'get',
        params: params
    })
}

export function getHisAccountQuantity(params) {
    return request({
        url: '/powerSystem/getHisAccountQuantity',
        method: 'get',
        params: params
    })
}

export function getHisCalculate(params) {
    return request({
        url: '/powerSystem/getHisCalculate',
        method: 'get',
        params: params
    })
}

export function sjUserInfo(params) {
    return request({
        url: '/powerCalculate/sjUserInfo',
        method: 'get',
        params: params
    })
}

export function sjUserInfoUpdate(params) {
    return request({
        url: '/powerCalculate/sjUserInfoUpdate',
        method: 'post',
        data: params
    })
}

export function getHelps(params) {
    return request({
        url: '/powerSystem/getHelps',
        method: 'get',
        params: params
    })
}


// export function getAllUsers(params) {
//     return request({
//         url: '/powerSystem/getAllUsers',
//         method: 'get',
//         params: params
//     })
// }

export function getAllUsersByName(params) {
    return request({
        url: '/powerSystem/getAllUsersByName',
        method: 'get',
        params: params
    })
}

export function getMaxNeedVolumePrice(params) {
    return request({
        url: '/powerCalculate/getMaxNeedVolumePrice',
        method: 'get',
        params: params
    })
}

export function getUserVolLevel(params) {
    return request({
        url: '/powerSystem/getUserVolLevel',
        method: 'get',
        params: params
    })
}
