<template>
  <div id="foot_menu">
    <!--    <tabbar v-model="index">-->
    <!--      <tabbar-item :link="item.link" ref="indexTab" v-for="(item) in menuData" v-bind:key="item.link">-->
    <!--        <img slot="icon" :src="item.icon">-->
    <!--        <span slot="label">{{ item.name }}</span>-->
    <!--      </tabbar-item>-->
    <!--    </tabbar>-->
    <div class="group_9 flex-row justify-between">
      <div class="image-text_4 flex-col" v-for=" (item) in menuData" v-bind:key="item.link" @click="itemClick(item)">
        <img
            class="label_1"
            referrerpolicy="no-referrer"
            :src="getIcon(item)"
        />
        <span class="text-group_4">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from '../../store/types'

export default {
  data() {
    return {
      menuData: this.getMenuData(),
      index: this.selectIndex()
    }
  },
  methods: {
    getMenuData() {
      return types.MENU_DATA_POWER;
    },
    getIcon(item) {
      if (this.$route.path.indexOf(item.link) > -1) {
        return item.activeIcon;
      }
      return item.icon;
    },
    selectIndex() {
      const menuData = this.getMenuData()
      for (let i = 0; i < menuData.length; i++) {
        if (this.$route.path.indexOf(menuData[i].link) > -1) {
          return i
        }
      }
      return 0;
    },
    itemClick(item) {
      this.$router.push(item.link);
    }
  }
}
</script>

<style>

.group_9 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 13.07vw;
  background: url(/img/tab-back.png) 100% no-repeat;
  background-size: 100% 100%;
  padding: 1.33vw 24.93vw 1.33vw 24.93vw;
}

.image-text_4 {
  margin-bottom: 0.27vw;
}

.label_1 {
  width: 6.4vw;
  height: 6.4vw;
}

.text-group_4 {
  overflow-wrap: break-word;
  color: rgba(58, 121, 247, 1);
  font-size: 2.66vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 2.67vw;
  align-self: center;
  margin-top: 1.07vw;
}


.text-group_5 {
  overflow-wrap: break-word;
  color: rgba(157, 161, 165, 1);
  font-size: 2.66vw;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  line-height: 2.67vw;
  align-self: center;
  margin-top: 1.34vw;
}
</style>
