/**
 * Created by superman on 17/2/16.
 * vuex types
 */
// 公共
export const COM_NAV_STATUS = 'COM_NAV_STATUS'
export const COM_HEADER_STATUS = 'COM_HEADER_STATUS'
export const COM_LOADING_STATUS = 'COM_LOADING_STATUS'

export const COM_SHOW_TOAST = 'COM_SHOW_TOAST'              //显示toast
export const COM_SHOW_SUCCESS = 'COM_SHOW_SUCCESS'          //显示成功toast
export const COM_SHOW_FAIL = 'COM_SHOW_FAIL'                //显示失败toast
export const COM_TOAST_MSG = 'COM_TOAST_MSG'                //显示toast文字
export const COM_SHOW_ALERT = 'COM_SHOW_ALERT'
export const COM_ALERT_MSG = 'COM_ALERT_MSG'
export const COM_SHOW_TIME_PICKER = 'COM_SHOW_TIME_PICKER'  //显示timepicker

export const LOGIN = 'login'

export const LOGOUT = 'logout'

export const TITLE = 'title'

export const APP_KEY = 'ABDFEADF'
export const PRODUCT_BBS = 'BBS'
export const PRODUCT_OFFICIAL_SITE = 'OFFICIAL_SITE'
export const PRODUCT_POWER='POWER'
export const PRODUCT = PRODUCT_POWER


export const MENU_DATA_POWER = [
  {link: '/lanhu_shouye', name: '首页', icon: '/img/shou-ye.png',activeIcon:'/img/shou-ye-active.png'},
  {link: '/lanhu_wode', name: '我的', icon: '/img/my.png',activeIcon:'/img/my-active.png'},
]
