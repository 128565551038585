<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <div class="box_2 flex-row justify-between">
          <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="/img/left-back-white.png"
              @click="back()"
          />
          <span class="text_1">实时计算</span>
        </div>
        <div class="box_3 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <div class="image-wrapper_1 flex-col">
              <img
                  class="image_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc00221d528ba7747d1e2f4865dca0747b7f296f4918bd8823f5ec9bc7b28f5a2.png"
              />
            </div>
            <div class="text-group_1 flex-col">
              <span class="text_2">{{ accountName }}</span>
              <span class="text_3">户号&nbsp;{{ accountNumber }}</span>
            </div>
          </div>
          <div class="text-wrapper_1 flex-col" @click="gotoUserInfo()"><span class="text_4">设置</span></div>
        </div>
      </div>
    </div>
    <div class="box_4 flex-col">
      <div class="block_2 flex-row justify-between">
        <span class="text_5">预估时间</span>
        <div class="image-text_2 flex-row justify-between" @click="showTimePicker=!showTimePicker">
          <span class="text-group_2">{{ form.meterMonth }}</span>
          <van-popup v-model="showTimePicker" position="bottom" style="height: 50%" round>
            <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                title="选择预估时间"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @cancel="onTimeOut"
                @confirm="onTimeIn"
                :changeFn="onShift"
                show-toolbar
            />
          </van-popup>
          <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
          />
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="block_4 flex-row justify-between">
        <span class="text_6">电压等级</span>
        <div class="image-text_3 flex-row justify-between" @click="showLevelPicker=!showLevelPicker">
          <span class="text-group_3">{{ form.volLevel }}</span>
          <van-popup v-model="showLevelPicker" position="bottom" style="height: 40%" round>
            <van-picker
                show-toolbar
                :columns="levelColumns"
                title="请选择电压等级"
                @change="onVolChange"
                @confirm="confirmVol"/>
          </van-popup>
          <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2427455258760374fbf232cf84d31c722bf860a86a7fe205d6480cf126bf2100.png"
          />
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_2 flex-row justify-between">
        <span class="text_7">总电量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.electricQuantity"/>
          <span class="text_8">度</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_11">峰电量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.electricQuantityUp"/>
          <span class="text_8">度</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_5 flex-row justify-between">
        <span class="text_13">谷电量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.electricQuantityLower"/>
          <span class="text_8">度</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_3 flex-row justify-between">
        <span class="text_9">尖电量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.electricQuantitySharp"/>
          <span class="text_8">度</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_6 flex-row justify-between">
        <span class="text_15">高峰电价</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.upPrice"/>
          <span class="text_8">元</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_7 flex-row justify-between">
        <span class="text_17">低谷电价</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.lowPrice"/>
          <span class="text_8">元</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_8 flex-row justify-between">
        <span class="text_19">尖峰电价</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.sharpPrice"/>
          <span class="text_8">元</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_10 flex-row justify-between">
        <span class="text_23">最大容量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.transformerCapacity"/>
          <span class="text_8">KVA</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <div class="text-wrapper_9 flex-row justify-between">
        <span class="text_21">最大需量</span>
        <div>
          <input type="text" class="input-text" placeholder="请输入" v-model="form.maxNeed"/>
          <span class="text_8">KVA</span>
        </div>
      </div>
      <div class="block_line flex-col"></div>
      <span class="text_25" v-if="form.discount && form.discount!=''">*&nbsp;最大需量超过260KVA</span>
      <div class="text-wrapper_11 flex-col" @click="calculateAgain()"><span class="text_26">重新计算</span></div>
    </div>
    <div class="box_5 flex-col">
      <div class="text-wrapper_12 flex-row justify-between">
        <span class="text_33">分时电价(峰)：</span>
        <span class="text_34">{{ form.timeFeeUp }}&nbsp;元</span>
      </div>
      <div class="group_4 flex-col"></div>
      <div class="text-wrapper_15 flex-row justify-between">
        <span class="text_33">分时电价(谷)：</span>
        <span class="text_34">{{ form.timeFeeLow }}&nbsp;元</span>
      </div>
      <div class="group_4 flex-col"></div>
      <div class="text-wrapper_15 flex-row justify-between">
        <span class="text_33">分时电价(尖峰)：</span>
        <span class="text_34">{{ form.timeFeeSharp }}&nbsp;元</span>
      </div>
      <div class="group_4 flex-col"></div>
      <div class="text-wrapper_15 flex-row justify-between">
        <span class="text_33">电度电价：</span>
        <span class="text_34">
          {{ getDianDu() }}&nbsp;元</span>
      </div>
      <div class="group_4 flex-col" v-if="checkData(form.signalFee)"></div>
      <div class="text-wrapper_15 flex-row justify-between" v-if="checkData(form.signalFee)">
        <span class="text_27">单一制电价：</span>
        <span class="text_28">{{ form.signalFee }}&nbsp;元</span>
      </div>
      <div class="group_3 flex-col" v-if="checkData(form.dualFeeCapacity)"></div>
      <div class="text-wrapper_14 flex-row justify-between" v-if="checkData(form.dualFeeCapacity)">
        <span class="text_31">两部制电价(最大容量)：</span>
        <span class="text_32">{{ form.dualFeeCapacity }}&nbsp;元</span>
      </div>
      <div class="group_1 flex-col" v-if=" checkData(form.dualFeeNeed)"></div>
      <div class="group_2 flex-row justify-between" v-if=" checkData(form.dualFeeNeed)">
        <div class="image-text_4 flex-row justify-between">
          <span class="text-group_4">两部制电价(最大需量)：</span>
          <div class="text-wrapper_13 flex-col" v-if="form.discount && form.discount!=''">
            <span class="text_29">9折</span>
          </div>
        </div>
        <span class="text_30">{{ form.dualFeeNeed }}&nbsp;元</span>
      </div>
      <div class="group_5 flex-col"
           v-if="checkData(form.criticalMaxNeed) && checkData(form.cirticalMaxCapactity)"></div>
      <div class="text-group_5 flex-col" v-if="checkData(form.criticalMaxNeed) && checkData(form.cirticalMaxCapactity)">
        <span class="text_35">临界值：</span>
        <span class="text_36">
          两部制的最大需量:&nbsp;{{ form.criticalMaxNeed }}KVA
        </span>
        <span class="text_36">
          两部制的最大容量:&nbsp;{{ form.cirticalMaxCapactity }}KVA
        </span>
      </div>
      <div class="group_6 flex-col" v-if="checkData(form.suggest) && checkData(form.economy)"></div>
      <div class="group_7 flex-row justify-between" v-if="checkData(form.suggest) && checkData(form.economy)">
        <span class="text_37">建议计费方式:</span>
        <div class="text-group_6 flex-col">
          <span class="text_38">{{ form.suggest }}</span>
          <span class="text_39">预计节约{{ form.economy }}元</span>
        </div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="text-wrapper_16 flex-row justify-between">
        <span class="text_40">根据您的历史用电量应选：</span>
        <span class="text_41">{{ hisSuggest }}</span>
      </div>
      <span class="paragraph_1">
        *温馨提示：
        <br/>
        1.以上测算结果仅包含工商业输配费用和上网电费，不包含上网环节线损费用、系统运行费用、政府性基金及附加以及居民/农业电费。
        <br/>
        2.测算后，若您发生容量、电价变更等情况，会导致本次测算结果不符合实际情况，届时请重新测算。
        <br/>
        3.计算结果仅供参考。
      </span>
    </div>
  </div>
</template>
<script>

import {Toast} from "vant";
import {checkLoging, checkNumber} from '@/utils/common'
import {calculateQuantity, getQuantity} from "@/api/api";

export default {
  data() {
    return {
      accountName: '',
      accountNumber: '',
      hisSuggest: '',
      constants: {},
      minDate: new Date(2000, 1, 1),
      maxDate: new Date(2060, 12, 31),
      currentDate: new Date(),
      showTimePicker: false,
      showLevelPicker: false,
      levelColumns: ['不满1kV', '交流10kV', '交流35kV', '交流110kV', '交流220kV'],
      form: {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: '',
        volLevel: '不满1kV',
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
      }
    };
  },
  mounted() {
    checkLoging();
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.hisSuggest = localStorage.getItem("hisSuggest")
    this.form.meterMonth = this.getTime()
    this.getQuantity();
  },
  watch: {
    'currentDate': {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  methods: {
    checkData(data) {
      return data && data != 0 && data != "0"
    },
    getDianDu() {
      if (this.form.timeFeeUp && checkNumber(this.form.timeFeeUp) &&
          this.form.timeFeeLow && checkNumber(this.form.timeFeeLow) &&
          this.form.timeFeeSharp && checkNumber(this.form.timeFeeSharp)) {
        let diandu = Number.parseFloat(this.form.timeFeeUp) +
            Number.parseFloat(this.form.timeFeeLow) +
            Number.parseFloat(this.form.timeFeeSharp)
        return diandu.toFixed(2)*100/100;
      }
      return '';
    },
    back() {
      history.go(-1)
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    // 取消时间弹出框按钮
    onTimeOut() {
      this.showTimePicker = true
    },
    // 将时间格式转化为正常的
    onShift() {
    },
    // 时间弹出框  确认按钮
    onTimeIn() {
      this.form.meterMonth = this.getTime()
      this.showTimePicker = true;
      this.getQuantity();
    },
    onDateChange(picker, value, index) {
      console.log(picker)
      console.log(value)
      console.log(index)
    },
    getTime() {
      return this.timeFormat(this.currentDate)
    },
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      // let day = time.getDate();
      return year + '年' + month + '月'
    },
    confirmVol() {
      this.getQuantity();
    },
    onVolChange(picker, value, index) {
      console.log(value)
      console.log(index)
      this.form.volLevel = value
    },
    getQuantity() {
      this.resetForm()
      let request = {
        "accountNumber": this.accountNumber,
        "volLevel": this.form.volLevel,
        "meterMonth": this.form.meterMonth,
      }
      getQuantity(request).then(res => {
        console.log(res);
        if (res.code == 20000 && res.data) {
          this.form = res.data;
        }
      });
    },
    calculateAgain() {
      if (!this.form.electricQuantity || this.form.electricQuantity == '' || !checkNumber(this.form.electricQuantity)) {
        Toast(`总电量不能为空且为数字.`);
        return;
      }
      if (!this.form.electricQuantityUp || this.form.electricQuantityUp == '' || !checkNumber(this.form.electricQuantityUp)) {
        Toast(`峰电量不能为空且为数字.`);
        return;
      }
      if (!this.form.electricQuantityLower || this.form.electricQuantityLower == '' || !checkNumber(this.form.electricQuantityLower)) {
        Toast(`谷电量不能为空且为数字.`);
        return;
      }
      if (!this.form.electricQuantitySharp || this.form.electricQuantitySharp == '' || !checkNumber(this.form.electricQuantitySharp)) {
        Toast(`尖电量不能为空且为数字.`);
        return;
      }
      if (!this.form.upPrice || this.form.upPrice == '' || !checkNumber(this.form.upPrice)) {
        Toast(`峰电价不能为空且为数字.`);
        return;
      }
      if (!this.form.lowPrice || this.form.lowPrice == '' || !checkNumber(this.form.lowPrice)) {
        Toast(`谷电价不能为空且为数字.`);
        return;
      }
      if (!this.form.sharpPrice || this.form.sharpPrice == '' || !checkNumber(this.form.sharpPrice)) {
        Toast(`尖峰电价不能为空且为数字.`);
        return;
      }
      if (!this.form.transformerCapacity || this.form.transformerCapacity == '' || !checkNumber(this.form.transformerCapacity)) {
        Toast(`最大容量不能为空且为数字.`);
        return;
      }
      if (!this.form.maxNeed || this.form.maxNeed == '' || !checkNumber(this.form.maxNeed)) {
        Toast(`最大需量不能为空且为数字.`);
        return;
      }

      calculateQuantity(this.form).then(res => {
        console.log(res)
        if (res.code == 20000 && res.data) {
          this.form = res.data;
        }
      })
    },
    gotoUserInfo() {
      this.$router.push("/userinfo")
    },
    resetForm() {
      let data = {
        userId: '',
        accountName: '',
        accountNumber: '',
        meterMonth: this.form.meterMonth,
        volLevel: this.form.volLevel,
        transformerCapacity: '',
        runCapacity: '',
        signalFee: '',
        dualFeeNeed: '',
        dualFeeCapacity: '',
        timeFeeUp: '',
        timeFeeLow: '',
        timeFeeSharp: '',
        criticalMaxNeed: '',
        cirticalMaxCapactity: '',
        calculateTime: '',
        calculateLaunch: '',
        calculateType: '',
        calculateBegin: '',
        calculateEnd: '',
        fixPriceSingle: '',
        upPrice: '',
        lowPrice: '',
        sharpPrice: '',
        needPrice: '',
        volumePrice: '',
        maxNeed: '',
        fixPriceDual: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        discount: '',
        suggest: '',
        economy: '',
      }
      this.form = data;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
