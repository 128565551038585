<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_2 flex-row justify-between">
        <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="/img/left-back-balck.png"
            @click="back()"
        />
        <span class="text_2">资讯详情</span>
      </div>
    </div>
    <!--    <div class="block_2 flex-col" v-for="item in newsDatas" :key="item.id">-->
    <!--      <div class="section_1 flex-col">-->
    <!--        <span class="text_3">{{ item.title }}</span>-->
    <!--        <div class="box_1 flex-row justify-between">-->
    <!--          <div class="image-text_1 flex-row justify-between">-->
    <!--            <div class="group_3 flex-col"></div>-->
    <!--            <span class="text-group_1">{{ getTime19(item) }}</span>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;          <span class="text_4">03月29日</span>&ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="block_3 flex-col">-->
    <!--      <div class="group_4 flex-col">-->
    <!--        <span class="text_5">北京无新增确诊病例新增出院1例累计治愈1…</span>-->
    <!--        <div class="box_2 flex-row justify-between">-->
    <!--          <div class="image-text_2 flex-row justify-between">-->
    <!--            <div class="section_2 flex-col"></div>-->
    <!--            <span class="text-group_2">本地资讯</span>-->
    <!--          </div>-->
    <!--          <span class="text_6">03月21日</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="block_2 flex-col">
      <div class="section_1 flex-col">
        <span class="text_3">{{ getTitle(newsData) }}</span>
        <div class="box_1 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <div class="group_3 flex-col"></div>
            <span class="text-group_1">{{ getTime19(newsData) }}</span>
          </div>
          <!--          <span class="text_4">03月29日</span>-->
        </div>
        <div class="flex-row justify-between">
          <img :src="getImage(newsData)" alt="" style="width: 85vw">
        </div>

        <div v-html="getContent(newsData)" class="html">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getNews, getNewsById} from "@/api/api";

export default {
  data() {
    return {
      constants: {},
      newsDatas: [],
      newsData: null,
    };
  },
  mounted() {
    // this.getNews();
  },
  created() {
    this.getNewsById();
  },
  methods: {
    back() {
      history.go(-1)
    },
    getImage(item) {
      let baseUrl = process.env.VUE_APP_BASE_API;
      if (item && item.img) {
        return baseUrl + "/file/getImgStream?idFile=" + item.img;
      }
      return "";
    },
    getTitle(item) {
      if (item && item.title) {
        return item.title;
      }
      return "";
    },
    getTime19(item) {
      if (item && item.modifyTime) {
        return item.modifyTime.substr(0, 10);
      }
      return "";
    },
    getContent(item) {
      if (item && item.content) {
        return item.content;
      }
      return "";
    },
    getNews() {
      getNews().then(res => {
        let baseUrl = process.env.VUE_APP_BASE_API;
        if (res && res.data && res.data.records) {
          for (let i = 0; i < res.data.records.length; i++) {
            res.data.records[i].img = baseUrl + res.data.records[i].img;
          }
          this.newsDatas = res.data.records;
        }
      })
    },
    getNewsById() {
      let id = this.$route.query.id;
      let params = {
        "id": id
      }
      getNewsById(params).then(res => {
        if (res && res.data) {
          this.newsData = res.data;
          console.log(this.newsData)
          console.log(this.newsData.title)
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
<style lang="scss" scoped>
.html {
  ::v-deep img {
    width: 100%;
  }
  font-size: 3.2vw;
}
</style>
