<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-row justify-between">
        <span class="text_1" style="white-space: break-spaces;width: 90vw;text-align: center;"
              :title="accountName">
          {{ accountName }}
        </span>
        <!--        <img-->
        <!--          class="label_1"-->
        <!--          referrerpolicy="no-referrer"-->
        <!--          src="./assets/img/SketchPng121b7682acfcd021388c669351315113b4c85e826a728197f8ceb7a0a97654d7.png"-->
        <!--        />-->
      </div>
      <div class="text-wrapper_1 flex-col"><span class="text_2" v-if="!checkAdmin">户号&nbsp;{{ accountNumber }}</span></div>
      <div class="box_2 flex-col">
        <div class="text-wrapper_2 flex-row justify-between">
          <span class="text_3">手机号</span>
          <span class="text_4">{{ this.form.contactWay }}</span>
        </div>
        <div class="box_3 flex-col"></div>
        <div class="text-wrapper_3 flex-row justify-between">
          <span class="text_5">地址</span>
          <span class="text_6">{{ this.form.address }}</span>
        </div>
      </div>
      <div class="box_4 flex-col">
        <!--        <div class="text-wrapper_4 flex-row justify-between">-->
        <!--          <span class="text_7">上月电量</span>-->
        <!--          <span class="text_8">{{ this.form.electricQuantityUp }}</span>-->
        <!--        </div>-->
        <!--        <div class="box_5 flex-col"></div>-->
        <div class="text-wrapper_5 flex-row justify-between">
          <span class="text_9">上月电量</span>
          <span class="text_10">{{ this.form.electricQuantity }}&nbsp;度</span>
        </div>
        <div class="box_6 flex-col"></div>
        <div class="text-wrapper_6 flex-row justify-between">
          <span class="text_11">尖电量</span>
          <span class="text_12">{{ this.form.electricQuantitySharp }}&nbsp;度</span>
        </div>
        <div class="box_7 flex-col"></div>
        <div class="text-wrapper_7 flex-row justify-between">
          <span class="text_13">峰电量</span>
          <span class="text_14">{{ this.form.electricQuantityUp }}&nbsp;度</span>
        </div>
        <div class="box_8 flex-col"></div>
        <div class="text-wrapper_8 flex-row justify-between">
          <span class="text_15">谷电量</span>
          <span class="text_16">{{ this.form.electricQuantityLower }}&nbsp;度</span>
        </div>
        <div class="box_9 flex-col"></div>
        <div class="text-wrapper_9 flex-row justify-between">
          <span class="text_17">现状计算方式</span>
          <span class="text_18" style="width: 45vw; white-space: break-spaces; text-align: right;">{{ this.form.payWay }}</span>
        </div>
        <div class="flex-col justify-center" style="margin-top: 5vw;" @click="exit()">
          <van-button type="primary" block color="#1f74e5">退 出</van-button>
        </div>
      </div>
    </div>
    <div class="section_3 flex-col">
      <div class="image-wrapper_1 flex-row">
        <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng444aa51a4f7a6bd0a5798477dee67ce916dd77d29ffa288bf0348017873c9b25.png"
            @click="gotoUserInfo()"
        />
      </div>
      <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3d25b01628d574f78cd2c3d959acea25c226f5a145ca6f8311c3babd3172b258.png"
      />
    </div>
    <FootMenu></FootMenu>
  </div>
</template>
<script>
import FootMenu from "@/components/footer/footMenu";
import {checkLoging, loginOut} from "@/utils/common";
import {sjUserInfo} from "@/api/api";

export default {
  components: {FootMenu},
  data() {
    return {
      accountName: '',
      accountNumber: '',
      hisSuggest: '',
      constants: {},
      form: {
        userId: '',
        address: '',
        contactWay: '',
        meterMonth: '',
        upMonthFee: '',
        electricQuantity: '',
        electricQuantitySharp: '',
        electricQuantityUp: '',
        electricQuantityLower: '',
        payWay: '',
        meterTimeUp: '',
        meterTimeThis: '',
        hisSuggest: '',
      }
    };
  },
  mounted() {
    checkLoging();
    this.form.userId = localStorage.getItem("userId")
    this.accountName = localStorage.getItem("accountName")
    this.accountNumber = localStorage.getItem("accountNumber")
    this.sjUserInfo();
  },
  methods: {
    checkAdmin() {
      return this.accountNumber == '1000000000000'
    },
    exit() {
      loginOut();
    },
    gotoUserInfo() {
      this.$router.push("/userinfo")
    },
    sjUserInfo() {
      sjUserInfo({"userId": this.form.userId}).then(res => {
        if (res && res.code == 20000) {
          this.form = res.data;
        }
      });
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.response.css"/>
