<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="section_2 flex-col">
        <div class="group_1 flex-row">
          <div class="block_1 flex-col">
            <div class="box_1 flex-row">
              <span class="text_1">9:41&nbsp;AM</span>
              <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc7bf5ec534ff0796fcd18c81919a54172d082e448f9b8bfad30b5555bdc28859.png"
              />
              <img
                class="image_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng6601bd5a61c2f9d93ba89c098f09c761eea5113bf5f57a0f4f8a84af77b7e916.png"
              />
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc1a791b610deddd0aa621e28e4079646383c06432e746db8d6baca267cdc7e7d.png"
              />
            </div>
            <div class="box_2 flex-row">
              <div class="text-group_1 flex-col">
                <span class="text_2">您好！</span>
                <span class="text_3">欢迎来到政务系统</span>
              </div>
            </div>
            <div class="box_3 flex-row">
              <div class="box_4 flex-row">
                <div class="image-text_1 flex-row justify-between">
                  <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng1779d5c1095ff52ee7edc59f2b64a79528d95a17acac56f56e321914af02f432.png"
                  />
                  <span class="text-group_2">请输入用户名</span>
                </div>
              </div>
            </div>
            <div class="box_5 flex-row">
              <div class="box_6 flex-row">
                <div class="image-text_2 flex-row justify-between">
                  <img
                    class="label_2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngbda2c34fe800efd1d72211a8fc9ccbc88794eb334708d38443c26ff066bb887b.png"
                  />
                  <span class="text-group_3">请输入密码</span>
                </div>
              </div>
            </div>
            <div class="box_7 flex-row">
              <div class="text-wrapper_1 flex-col"><span class="text_4">登录</span></div>
            </div>
            <div class="text-wrapper_2 flex-row"><span class="text_5">还没有账号？立即注册</span></div>
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngb3c4bd89ca7c0d25126cb5aebbb247369df3fd3a523721f79ba52c8f436309e7.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.response.css" />
